import React, { Component } from "react";
import PrivacyHeader from "../components/PrivacyHeader";
import { Table } from "react-bootstrap";
export default class Privacy extends Component {
  render() {
    return (
      <React.Fragment>
        <PrivacyHeader />
        <div className="pnt">
          <h2>Account Deletion Policy</h2>
          {/* <h4>Oh, we take it very seriously!</h4> */}
          <h4>Last Update (9th March'2021)</h4>
          <h5>PLEASE READ THIS POLICY CAREFULLY BEFORE USING THE PLATFORM</h5>
          {/* <h5>Privacy Policy</h5> */}
          <p>
            Dear User, We're sorry to hear you're considering closing your Junio
            account. To initiate this process, please connect with our customer
            support team through the 'Help' section in the Junio app. As Junio
            is a product regulated by the Reserve Bank of India (RBI), we need
            to verify specific details prior to closing your account for your
            security and compliance with regulatory standards.
          </p>
          <p>
            Any remaining balance on your Junio card will be refunded to your
            original source of funding. To ensure a smooth and accurate refund,
            we will require confirmation of this source. Please be prepared to
            provide this information during your interaction with our support
            team.
          </p>
          <p>
            As part of our adherence to RBI guidelines, your account data will
            be kept securely with us for a period of 10 years following the
            closure. This is to ensure we maintain the highest standards of
            compliance and data protection for our users.
          </p>
          <p>
            Thank you for your understanding and cooperation throughout this
            process.
          </p>
        </div>
      </React.Fragment>
    );
  }
}
